import React, { ChangeEvent } from 'react';
// Import your Alert SVG component
import Alert from '@/SVG/alert';

interface InputProps {
    id: string;
    type: string;
    label: string;
    placeholder: string;
    value: string;
    color?: string;
    readonly?: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
    errorMessage?: string;
}

function Input({
    id,
    type,
    label,
    value,
    placeholder,
    color = 'text-black-200',
    onChange,
    readonly,
    onBlur,
    errorMessage
}: InputProps) {
    return (
        <div className='flex flex-col mb-4'>
            <label htmlFor={id} className={`font-medium text-base leading-6 ${color}`}>
                {label}
            </label>
            <div className='relative'>
                <input
                    id={id}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    readOnly={readonly}
                    autoComplete='off'
                    className={`flex items-center self-stretch gap-2 py-4 px-3 rounded-md border ${errorMessage ? 'border-red-100' : 'border-grey-300'} bg-white shadow-sm mt-1 w-full placeholder:text-gray-700`}
                />
                {errorMessage && (
                    <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                        <Alert />
                    </div>
                )}
            </div>
            {errorMessage && (
                <p className='text-red-100 font-normal text-[0.875rem]'>{errorMessage}</p>
            )}
        </div>
    );
}

export default Input;
