import * as React from 'react';
import { SVGProps } from 'react';

interface AlertProps extends SVGProps<SVGSVGElement> {
    onClick?: () => void;
}

const Alert = ({ onClick, ...props }: AlertProps) => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_821_2227)'>
            <path
                d='M7.99967 5.33325V7.99992M7.99967 10.6666H8.00634M14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992Z'
                stroke='#F04438'
                strokeWidth='1.33333'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_821_2227'>
                <rect width='16' height='16' fill='white' />
            </clipPath>
        </defs>
    </svg>
);

export default Alert;
