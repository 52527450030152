import { GetServerSideProps } from 'next/types';
import { QueryClient, dehydrate } from '@tanstack/react-query';
import LoginPage from '@/components/layouts/login/loginPage/loginPage';

export default function Home() {
    return (
        <>
            <LoginPage />
        </>
    );
}

export const getServerSideProps: GetServerSideProps = async () => {
    const queryClient = new QueryClient();

    // Fetching home page data from Strapi

    return {
        props: {
            dehydratedState: dehydrate(queryClient)
        }
    };
};
