import React from 'react';

interface ButtonProps {
    type: 'button' | 'submit' | 'reset';
    onClick?: () => void;
    disabled?: boolean;
    children: React.ReactNode;
    width?: number | string;
}

function Button({ type, onClick, children, disabled, width = 'w-full' }: ButtonProps) {
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={`justify-center self-stretch gap-2 rounded-xl ${disabled ? 'bg-gray-400' : 'bg-blue-100'} py-4 focus:ring-indigo-500 focus:border-indigo-500 block border-gray-300 rounded-md text-white hover:bg-gray text-[1.0rem] sm:text-[1.0rem] font-medium ${width}`}
        >
            {children}
        </button>
    );
}

export default Button;
