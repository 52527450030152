import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Image from 'next/image';
import Input from '@/components/input/input'; // Ensure this path is correct
import Button from '@/components/button/button'; // Ensure this path is correct
import { login } from '@/stores/auth/authSlice';
import { useRouter } from 'next/router';
import { useAppDispatch } from '@/components/hooks/useAppDispatch';
const validationSchema = Yup.object({
    username: Yup.string().required('Required'),
    password: Yup.string().required('Required')
});

const LoginPage = () => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema,
        onSubmit: (values, { setSubmitting, setFieldError }) => {
            console.log('Logging in with', values.username, values.password);
            const credentials = { username: values.username, passcode: values.password };
            dispatch(login(credentials))
                .unwrap()
                .then((response) => {
                    // Handle success, possibly saving token in localStorage here
                    router.push('/home');
                })
                .catch((error) => {
                    // Handle errors
                    if (error.response.statusCode === 400) {
                        // Instead of setting a general login error, set the error specifically for the username field
                        setFieldError('username', error.response.message.replace(/_/g, ' '));
                    } else {
                        // Handle other errors or set a generic error message
                        setFieldError('username', error.response.message.replace(/_/g, ' '));
                    }
                })
                .finally(() => setSubmitting(false));
        }
    });

    return (
        <div className='min-h-screen flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 mt-16'>
            <div className='mx-auto w-full max-w-md lg:max-w-xl px-4 sm:px-0'>
                <h1 className='mt-6 text-center text-2xl text-black font-inter font-semibold'>
                    Welcome to Merchant App
                </h1>
                <form className='mt-8 space-y-6' onSubmit={formik.handleSubmit}>
                    <Input
                        id='username'
                        type='text'
                        label='Username'
                        placeholder='Username'
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorMessage={
                            formik.touched.username && formik.errors.username
                                ? formik.errors.username
                                : ''
                        }
                    />
                    <Input
                        id='password'
                        type='password'
                        label='Password'
                        placeholder='Password'
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorMessage={
                            formik.touched.password && formik.errors.password
                                ? formik.errors.password
                                : ''
                        }
                    />
                    <div>
                        <Button type='submit'>Login</Button>
                    </div>
                </form>
            </div>
            <div className='flex py-4 px-12 flex-col justify-center items-center gap-1  mt-64'>
                <a
                    href='https://loynova.com'
                    className='font-medium text-indigo-600 hover:text-indigo-500'
                >
                    <Image src='/images/logo.png' alt='LOYNOVA' width={159} height={88} />
                </a>
            </div>
        </div>
    );
};

export default LoginPage;
